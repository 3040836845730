import React from 'react'

import Layout from '../components/Layout/Layout'
import Container from '../components/Container/Container'
import Seo from '../components/Seo/Seo'
import Image from '../components/Image/Image'

function onSayHello(e) {
  e.preventDefault();
  // eslint-disable-next-line
  Intercom('showNewMessage', 'Hi, I have a question about Ally.');
}

const Manager = () => {
  return (
    <Layout pageName="contact">
      <Seo />
      <Container>
        <section className="contact-us">
          <h1 className="heading heading--big">Contact Us</h1>
          <p className="contact-us__subheading">We love to hear from you.</p>
          <div className="questions row">
            <div className="question col-xs-12 col-sm-6 col-md-4">
              <Image
                filename="contact-questions.png"
                alt="Questions and Support"
                className="question__image"
              />
              <h2 className="heading heading--md">Questions & Support</h2>
              <p className="question__text">
                Things not going smoothly, or need a hand? Our team is here to
                help.
              </p>
              <a
                href="mailto:support@getally.com?subject=Important!"
                className="question__btn btn btn--blue"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get Support
              </a>
            </div>
            <div className="question col-xs-12 col-sm-6 col-md-4 col-md-offset-4">
              <Image
                filename="contact-feedback.png"
                alt="Questions and Support"
                className="question__image"
              />
              <h2 className="heading heading--md">Feedback & Suggestions</h2>
              <p className="question__text">
                Have some feedback or an idea? Let us know in our feedback
                forum.
              </p>
              <a
                href="/"
                className="question__btn btn btn--blue"
                onClick={onSayHello}
              >
                Post a Topic
              </a>
            </div>
            <div className="question col-xs-12 col-sm-6 col-md-4">
              <Image
                filename="contact-report.png"
                alt="Questions and Support"
                className="question__image"
              />
              <h2 className="heading heading--md">Report a Vulnerability</h2>
              <p className="question__text">
                Found a security issue? Request an invite to our bug bounty
                program.
              </p>
              <a
                href="https://help.getally.com/collections/1804140-security-docs"
                className="question__btn btn btn--blue"
              >
                See Security Docs
              </a>
            </div>
            <div className="question col-xs-12 col-sm-6 col-md-4 col-md-offset-4">
              <Image
                filename="contact-hello.png"
                alt="Questions and Support"
                className="question__image"
              />
              <h2 className="heading heading--md">Say Hello!</h2>
              <p className="question__text">
                For press enquiries, or anything else you need, drop us a line
                and we’ll help.
              </p>
              <a
                href="/"
                className="question__btn btn btn--blue"
                onClick={onSayHello}
              >
                Say hello
              </a>
            </div>
          </div>
        </section>

        <section className="find-us">
          <p className="heading heading--md">Find us on...</p>
          <a href="/">
            <Image filename="github" alt="github" className="find-us__icon" />
          </a>
        </section>

        <section className="anything">
          <p className="heading heading--md">Anything else?</p>
          <p className="anything__text">Our friendly support team can help.</p>
          <a href="mailto:support@getally.com" className="btn btn--blue">
            support@getally.com
          </a>
        </section>
      </Container>
    </Layout>
  )

  
}

export default Manager
